import { useIntl } from "react-intl";
import Image from "next/image";

import { Row, Column } from "@omni/layout";

import styles from "./Header.module.css";
import anzLogo from "./anzLogo.png";
import messages from "./messages";

interface Props {
  title?: string;
  link?: string;
  children?: React.ReactNode;
}

function Header({ title = "", link = "", children }: Props) {
  const { formatMessage } = useIntl();

  const titleText = messages[title] ? formatMessage(messages[title]) : title;
  return (
    <header id="anzLogOn" className={styles.branding}>
      <Row className={styles.content} style={{ width: "100%" }}>
        <Column style={{ alignSelf: "flex-end" }}>
          <a
            href={link}
            rel="noopener noreferrer"
            className={styles.logo}
            title={title}
            aria-label="Go to ANZ Log On"
          >
            <Image
              src={anzLogo}
              alt="ANZ Logo"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              style={{ overflow: "auto", height: "auto" }}
            />
          </a>
        </Column>
        <Column>
          <span data-testid="title">{titleText}</span>{" "}
        </Column>
        <Column className={styles["switch_container"]}>{children}</Column>
      </Row>
    </header>
  );
}

export default Header;
