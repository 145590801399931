import { useIntl } from "react-intl";

import { Row, Column } from "@omni/layout";

import styles from "./Footer.module.css";
import messages from "./messages";

interface Props {
  isMandatoryFieldsShown: boolean;
  digitalServiceStatusLink: string;
  securityDeviceUserGuideLink: string;
  onlineResourcesHelpLink: string;
  bankingSafeLink: string;
}

export function Footer({
  isMandatoryFieldsShown = false,
  digitalServiceStatusLink,
  securityDeviceUserGuideLink,
  onlineResourcesHelpLink,
  bankingSafeLink,
}: Props) {
  const { formatMessage } = useIntl();

  return (
    <footer className={styles.content}>
      <Row style={{ width: "100%" }} className={styles["footer_row"]}>
        <Column
          style={{
            width: isMandatoryFieldsShown ? "75%" : "100%",
            flex: "auto",
          }}
          className={styles["footer_column_one"]}
        >
          <Row style={{ width: "100%" }}>
            <Column style={{ width: "100%" }}>
              <span>{formatMessage(messages.statusUpdateInstruction)}</span>{" "}
              <a
                id="digital-service-status-page"
                href={digitalServiceStatusLink}
                target="_blank"
                rel="noopener noreferrer external"
              >
                {formatMessage(messages.statusUpdatePage)}
              </a>
            </Column>
          </Row>
          <Row style={{ width: "100%", marginTop: "10px" }}>
            <Column style={{ width: "100%" }}>
              <span>{formatMessage(messages.logonHelpInstruction)}</span>{" "}
              <a
                id="security-device-user-guide"
                href={securityDeviceUserGuideLink}
                target="_blank"
                rel="noopener noreferrer external"
              >
                {formatMessage(messages.securityDeviceGuide)}
              </a>{" "}
              <span>{formatMessage(messages.or)}</span>{" "}
              <a
                id="online-resources-and-help"
                href={onlineResourcesHelpLink}
                target="_blank"
                rel="noopener noreferrer external"
              >
                {formatMessage(messages.onlineResources)}
              </a>
            </Column>
          </Row>
          <Row style={{ width: "100%", marginTop: "10px" }}>
            <Column style={{ width: "100%" }}>
              <a
                id="learn-how-to-protect"
                href={bankingSafeLink}
                target="_blank"
                rel="noopener noreferrer external"
              >
                {formatMessage(messages.securityInstructions)}
              </a>
            </Column>
          </Row>
        </Column>
        {isMandatoryFieldsShown && (
          <Column
            style={{
              width: "25%",
              flex: "auto",
              alignSelf: "center",
              textAlign: "right",
            }}
            className={styles["footer_column-two"]}
          >
            <span>*</span> <span>{formatMessage(messages.mandatory)}</span>
          </Column>
        )}
      </Row>
    </footer>
  );
}

export default Footer;
