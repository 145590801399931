import { defineMessages, MessageDescriptor } from 'react-intl';

const messages: Record<string | number | symbol, MessageDescriptor> = {
    "/ss/activate-adk": {
        id: 'Header./ss/activate-adk',
        defaultMessage: 'ANZ Digital Key Activation'
    },
    "/ss/unblock-token": {
        id: 'Header./ss/unblock-token',
        defaultMessage: 'Unblock Token'
    },
};

export default defineMessages(messages);