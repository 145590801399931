import { defineMessages } from 'react-intl';

export default defineMessages({
    statusUpdateInstruction: {
        id: 'Footer.statusUpdateInstruction',
        defaultMessage: 'For current status updates for AU and NZ customers, visit',
    },
    statusUpdatePage: {
        id: 'Footer.statusUpdatePage',
        defaultMessage: 'ANZ Digital Services Status Page',
    },
    logonHelpInstruction: {
        id: 'Footer.logonHelpInstruction',
        defaultMessage: 'For log on help, refer to ',
    },
    or: {
        id: 'Footer.or',
        defaultMessage: 'or',
    },
    securityDeviceGuide: {
        id: 'Footer.securityDeviceGuide',
        defaultMessage: 'Security Device User Guide',
    },
    onlineResources: {
        id: 'Footer.onlineResources',
        defaultMessage: 'Online resources and help',
    },
    securityInstructions: {
        id: 'Footer.securityInstructions',
        defaultMessage: 'Learn how to protect and manage your online security',
    },
    mandatory: {
        id: 'Footer.mandatory',
        defaultMessage: 'Mandatory Field',
    },
});