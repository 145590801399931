// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ai3CtNxFK0UX6lUh {\n    background-color: rgb(245, 245, 245);\n    overflow: auto;\n}\n\n.BvKk7TaH6j0q3cEV {\n    max-width: 950px;\n    margin: 0 auto;\n    font-size: 13px;\n    padding: 3em 1em 0 1em;\n}\n\n.BvKk7TaH6j0q3cEV a {\n    color: #007dba;\n}\n\n.BvKk7TaH6j0q3cEV a:link,\n.BvKk7TaH6j0q3cEV a:visited {\n    text-decoration: none\n}\n\n.BvKk7TaH6j0q3cEV a:hover {\n    text-decoration: underline\n}", "",{"version":3,"sources":["webpack://./src/app/page.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".body {\n    background-color: rgb(245, 245, 245);\n    overflow: auto;\n}\n\n.content {\n    max-width: 950px;\n    margin: 0 auto;\n    font-size: 13px;\n    padding: 3em 1em 0 1em;\n}\n\n.content a {\n    color: #007dba;\n}\n\n.content a:link,\n.content a:visited {\n    text-decoration: none\n}\n\n.content a:hover {\n    text-decoration: underline\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "ai3CtNxFK0UX6lUh",
	"content": "BvKk7TaH6j0q3cEV"
};
export default ___CSS_LOADER_EXPORT___;
